import React from 'react';
import { Link } from 'gatsby';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

const Page = () => (
  <Layout>
    <SEO title="Page" />
    <div className="some-page">
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
    </div>
  </Layout>
);

const FirstSection = () => <section className="first-section" />;
const SecondSection = () => <section className="second-section" />;
const ThirdSection = () => <section className="third-section" />;
const FourthSection = () => <section className="fourth-section" />;
const FifthSection = () => <section className="fifth-section" />;

export default Page;
